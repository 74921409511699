<template>
  <div class="domain-search-container">
    <b-input-group class="input-group-container">
      <div class="search-components-container">
        <div class="search-bar-tld-options-container">
          <b-form-input
            v-model="query"
            type="text"
            placeholder="Search"
            class="small-input domain-search-input"
            aria-describedby="domain-search-input-feedback"
            :state="feedbackMessage ? false : undefined"
            :formatter="toLowerCase"
            @keyup.enter="query.length >= 4 && submit()"
            @input="resetInputState"
          />
          <b-dropdown
            ref="tld-dropdown"
            class="tlds-dropdown"
            variant="primary"
            :disabled="disableButtons"
            :text="$mq === 'sm' ? 'TLDS' : 'Additional TLDs'"
            @hide="resetDropdownState"
          >
            <b-form-input
              v-model="filter"
              placeholder="Search"
              class="small-search ml-1 mr-1 mb-2"
              @input="dropdownScrollTop"
              @keyup.enter="setTldIfValid(filter)"
            />
            <hr class="m-0">
            <b-dropdown-form
              v-show="filteredTlds?.length > 0"
              ref="tld-dropdown-form"
              class="tlds-dropdown-form"
            >
              <b-dropdown-item
                v-for="tld in filteredTlds"
                :key="tld"
                @click="dropdownInput(tld)"
              >
                {{ tld }}
              </b-dropdown-item>
            </b-dropdown-form>
            <div v-if="filteredTlds?.length === 0" class="no-results">
              No results
            </div>
          </b-dropdown>
        </div>
        <b-input-group-append class="search-button-container">
          <b-button
            variant="primary"
            class="domain-search-button"
            :disabled="disableButtons"
            aria-label="Search button"
            @click="submit"
          >
            <b-spinner v-if="searchInProgress" small />
            <span v-else>
              Search
            </span>
          </b-button>
        </b-input-group-append>
      </div>
      <b-form-invalid-feedback
        id="domain-search-input-feedback"
      >
        {{ feedbackMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validDomain } from '@/common'

const INVALID_DOMAIN_FORMAT_ERROR = 'Invalid domain. A domain can only contain letters, numbers, and dashes before the \'.\', and only specific TLDs after it.'
const INVALID_DOMAIN_LENGTH_ERROR = 'Invalid domain. A domain name can\'t be longer than 63 characters.'

export default {
  name: 'DomainSearch',

  components: {},

  props: {
    bus: {
      type: Object,
      required: true,
    },
    domainSuggestions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      query: '',
      feedbackMessage: undefined,
      dropdownBtnText: 'Additional TLDs',
      searchInProgress: false,
      filter: null,
    }
  },

  computed: {
    ...mapGetters('domains', [
      'domainSearchResults',
      'selectedDomain',
      'domainSearch',
      'tlds',
    ]),
    ...mapGetters('vouchers', [
      'unRedeemedVoucherByProductCategory',
    ]),
    filteredTlds() {
      if (!this.filter) return this.tlds

      const res = this.tlds.filter(tld => {
        if (tld.includes(this.filter)) return tld
      })

      return this.sortFilteredTlds(res, this.filter)
    },
    disableButtons() {
      return this.query.length < 4 || this.searchInProgress
    },
    isSearchFormatValid() {
      return this.validDomain().test(this.query)
    },
    isSearchLengthValid() {
      return /^[a-z0-9-]{1,63}(?:\..*)?$/.test(this.query)
    },
  },

  watch: {
    async domainSearchResults(newResults, _oldResults) {
      if (!newResults) return

      if (newResults.length > 0) {
        this.bus.$emit('domain-selected', false)
        const hasQueryInResults = newResults.find(result => result.domain === this.query)

        hasQueryInResults ?
          this.handleAvailableDomain() :
          this.handleUnavailableDomain()
      }
      else {
        this.handleUnavailableDomain()
      }

      await this.logSearchResults()
    },
    domainSuggestions: {
      immediate: true,
      handler(newSuggestions) {
        if (newSuggestions?.length) {
          this.query = newSuggestions[0].domain
          this.$nextTick (() => {
            this.submit()
          })
        }
      },
    },
  },

  async mounted() {
    await this.fetchTlds()
  },

  methods: {
    ...mapActions('domains', [
      'searchDomains',
      'setDomainSearchResults',
      'fetchTlds',
      'setSelectedDomain',
    ]),
    ...mapActions('domainSuggestions', [
      'clearSuggestions',
    ]),
    handleUnavailableDomain() {
      this.$emit('domain-unavailable')
    },

    handleAvailableDomain() {
      this.$emit('domain-available')
    },

    setTldIfValid(search) {
      if (this.tlds.includes(search)) {
        this.dropdownInput(search)
        this.$refs['tld-dropdown'].hide()
      } else if (this.filteredTlds?.length > 0) {
        this.dropdownInput(this.filteredTlds[0])
        this.$refs['tld-dropdown'].hide()
      }
    },

    dropdownScrollTop() {
      this.$refs['tld-dropdown-form'].scrollTop = 0
    },

    sortFilteredTlds(tlds, filter) {
      const prepend = []
      const append = []
      for (let i = 0; i < tlds.length; i++) {
        if (tlds[i].substring(0, filter.length) === filter) {
          prepend.push(tlds[i])
        } else {
          append.push(tlds[i])
        }
      }
      return [...prepend, ...append]
    },

    dropdownInput(tld) {
      this.resetInputState()
      this.query = this.query.split(".", 2)[0] + '.' + tld
    },

    async submit() {
      await this.setSelectedDomain(null)
      await this.searchDomains(null)
      await this.setDomainSearchResults(null)

      this.resetInputState()

      if (!this.query?.includes('.')) {
        this.query += ".com"
      }

      if (!this.isSearchFormatValid) {
        this.feedbackMessage = INVALID_DOMAIN_FORMAT_ERROR
        return
      }
      if (!this.isSearchLengthValid) {
        this.feedbackMessage = INVALID_DOMAIN_LENGTH_ERROR
        return
      }

      // Add .com if no TLD was specified.
      this.searchInProgress = true
      this.query = this.query.indexOf('.') === -1 ? `${this.query}.com` : this.query

      //Remove old domain suggestions, search for domains.
      if(!this.domainSuggestions.map(suggestion => suggestion.domain).includes(this.query)) {
        await this.clearSuggestions()
      }
      await this.searchDomains(this.query)

      this.searchInProgress = false
    },

    async logSearchResults() {
      const searchParams = {
        'searched': this.domainSearch,
        'results': this.domainSearchResults,
      }

      const logInfo = {
        name: 'domain-search',
        searchParams: searchParams,
      }

      await this.bus.$emit('log-domain-interaction', logInfo)
    },

    toLowerCase(value) {
      return value.toLowerCase()
    },

    resetInputState() {
      this.feedbackMessage = undefined
    },

    resetDropdownState() {
      this.filter = null
      this.dropdownScrollTop()
    },

    validDomain: validDomain,
  },
}
</script>

<style lang="scss" scoped>

.domain-search-container {
  .input-group-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .search-components-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .search-bar-tld-options-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .domain-search-input {
          height: 3.125em !important;
          min-height: 3.125em !important;
          margin-bottom: 0 !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        .tlds-dropdown {
          margin-right: 0.5em;
        }
        ::v-deep .tlds-dropdown .btn-primary {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
        .small-search {
          height: 2.188em !important;
          min-height: 2.188em !important;
          width: 11.375em;
        }
        .tlds-dropdown-form {
          max-height: 30vh;
          overflow-y: auto;

          .b-dropdown-form {
            padding: 0;
          }
          .no-results {
            text-align: center;
            color: dimgray;
            font-weight: $ct-ui-font-weight-2;
          }
        }
      }
      .search-button-container {
        select {
          height: 3.125em;
          min-height: 3.125em;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .domain-search-container {
    .input-group-container {
      .search-components-container {
        flex-direction: column;

        .search-bar-tld-options-container {
          .tlds-dropdown {
            margin-right: 0;
          }
        }
        .search-button-container {
          .domain-search-button {
            width: 100%;
            margin-top: 0.5em;
          }
        }
      }
    }
  }
}
</style>
