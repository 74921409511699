var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-search-container" },
    [
      _c(
        "b-input-group",
        { staticClass: "input-group-container" },
        [
          _c(
            "div",
            { staticClass: "search-components-container" },
            [
              _c(
                "div",
                { staticClass: "search-bar-tld-options-container" },
                [
                  _c("b-form-input", {
                    staticClass: "small-input domain-search-input",
                    attrs: {
                      type: "text",
                      placeholder: "Search",
                      "aria-describedby": "domain-search-input-feedback",
                      state: _vm.feedbackMessage ? false : undefined,
                      formatter: _vm.toLowerCase,
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        _vm.query.length >= 4 && _vm.submit()
                      },
                      input: _vm.resetInputState,
                    },
                    model: {
                      value: _vm.query,
                      callback: function ($$v) {
                        _vm.query = $$v
                      },
                      expression: "query",
                    },
                  }),
                  _c(
                    "b-dropdown",
                    {
                      ref: "tld-dropdown",
                      staticClass: "tlds-dropdown",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.disableButtons,
                        text: _vm.$mq === "sm" ? "TLDS" : "Additional TLDs",
                      },
                      on: { hide: _vm.resetDropdownState },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "small-search ml-1 mr-1 mb-2",
                        attrs: { placeholder: "Search" },
                        on: {
                          input: _vm.dropdownScrollTop,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setTldIfValid(_vm.filter)
                          },
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                      _c("hr", { staticClass: "m-0" }),
                      _c(
                        "b-dropdown-form",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filteredTlds?.length > 0,
                              expression: "filteredTlds?.length > 0",
                            },
                          ],
                          ref: "tld-dropdown-form",
                          staticClass: "tlds-dropdown-form",
                        },
                        _vm._l(_vm.filteredTlds, function (tld) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: tld,
                              on: {
                                click: function ($event) {
                                  return _vm.dropdownInput(tld)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(tld) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm.filteredTlds?.length === 0
                        ? _c("div", { staticClass: "no-results" }, [
                            _vm._v("\n            No results\n          "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-input-group-append",
                { staticClass: "search-button-container" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "domain-search-button",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.disableButtons,
                        "aria-label": "Search button",
                      },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm.searchInProgress
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [
                            _vm._v("\n            Search\n          "),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: "domain-search-input-feedback" } },
            [_vm._v("\n      " + _vm._s(_vm.feedbackMessage) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }